//Datos de Prueva para los reportes

import React from 'react';
import axios from 'axios';
const dataStudent=[
    
    {
        "idCiclo": 6,
        "firstName": "Ashley",
        "lastName": "Ampuero Tamani",
        "dni": "75993316",
        "gender": "Female",
        "age": 25,
        "email": "ashleyampuerotamani@gmail.com",
        "matricula": "---"
    },
    {
        "idCiclo": 6,
        "firstName": "Mauro",
        "lastName": "Cabanilla Diaz",
        "dni": "73252212",
        "gender": "Female",
        "age": 19,
        "email": "maurocabanilladiaz@gmail.com",
        "matricula": "---"
    },
    {
        "idCiclo": 6,
        "firstName": "Frank Patrick",
        "lastName": "Cahuachi Chu",
        "dni": "72848961",
        "gender": "male",
        "age": 20,
        "email": "frankpatrickcahuachichu@gmail.com",
        "matricula": "---"
    },
    {
        "idCiclo": 6,
        "firstName": "Milton",
        "lastName": "Cerron Saboya",
        "dni": "76111521",
        "gender": "Male",
        "age": 24,
        "email": "miltoncerronsaboya@gmail.com",
        "matricula": "---"
    },
    {
        "idCiclo": 6,
        "firstName": "Willy Joseph",
        "lastName": "Chavez Prada",
        "dni": "72606080",
        "gender": "Female",
        "age": 25,
        "email": "willyjosephchavezprada@gmail.com",
        "matricula": "---"
    },
    {
        "idCiclo": 6,
        "firstName": "Marcos Alberto",
        "lastName": "Chino Ruiz",
        "dni": "82112232",
        "gender": "Female",
        "age": 18,
        "email": "marcosalbertochinoruiz@gmail.com",
        "matricula": "---"
    },
    {
        "idCiclo": 6,
        "firstName": "Angela Mercedes",
        "lastName": "Escalante Lopez",
        "dni": "80211223",
        "gender": "Male",
        "age": 22,
        "email": "angelamercedesescalantelopez@gmail.com",
        "matricula": "---"
    },
    {
        "idCiclo": 6,
        "firstName": "Carmen Lucia",
        "lastName": "Galindo Bardales",
        "dni": "75244140",
        "gender": "Male",
        "age": 19,
        "email": "carmenluciagalindobardales@gmail.com",
        "matricula": "---"
    },
    {
        "idCiclo": 6,
        "firstName": "Jhimmy Alan",
        "lastName": "Garcia Vargas",
        "dni": "42983734",
        "gender": "Female",
        "age": 24,
        "email": "jhimmyalangarciavargas@gmail.com",
        "matricula": "---"
    },
    {
        "idCiclo": 6,
        "firstName": "Jeremy",
        "lastName": "Gomez Sanchez",
        "dni": "75897985",
        "gender": "Female",
        "age": 22,
        "email": "jeremygomezsanchez@gmail.com",
        "matricula": "---"
    },
    {
        "idCiclo": 6,
        "firstName": "Ramiro",
        "lastName": "Gonzales Vela",
        "dni": "62159880",
        "gender": "Male",
        "age": 23,
        "email": "ramirogonzalesvela@gmail.com",
        "matricula": "---"
    },
    {
        "idCiclo": 6,
        "firstName": "Charles Jonathan",
        "lastName": "Leon Tafur",
        "dni": "76726026",
        "gender": "Female",
        "age": 24,
        "email": "charlesjonathanleontafur@gmail.com",
        "matricula": "---"
    },
    {
        "idCiclo": 6,
        "firstName": "Jefferson",
        "lastName": "Lopez Ramirez",
        "dni": "72838444",
        "gender": "Male",
        "age": 21,
        "email": "jeffersonlopezramirez@gmail.com",
        "matricula": "---"
    },
    {
        "idCiclo": 6,
        "firstName": "Jhoana Edith",
        "lastName": "Mejia Morales",
        "dni": "75941130",
        "gender": "Female",
        "age": 22,
        "email": "jhoanaedithmejiamorales@gmail.com",
        "matricula": "---"
    },
    {
        "idCiclo": 6,
        "firstName": "Cristofer Joan",
        "lastName": "Panduro Torres",
        "dni": "76101521",
        "gender": "Female",
        "age": 18,
        "email": "cristoferjoanpandurotorres@gmail.com",
        "matricula": "---"
    },
    {
        "idCiclo": 6,
        "firstName": "Yhordy Alfredo",
        "lastName": "Peralta Quispe",
        "dni": "76111522",
        "gender": "Female",
        "age": 23,
        "email": "yhordyalfredoperaltaquispe@gmail.com",
        "matricula": "---"
    },
    {
        "idCiclo": 6,
        "firstName": "Daniel Andrey",
        "lastName": "Piña Reategui",
        "dni": "76111527",
        "gender": "Female",
        "age": 19,
        "email": "danielandreypinareategui@gmail.com",
        "matricula": "---"
    },
    {
        "idCiclo": 6,
        "firstName": "Aaron",
        "lastName": "Rengifo Choy Sanchez",
        "dni": "74405540",
        "gender": "Female",
        "age": 22,
        "email": "aaronrengifochoysanchez@gmail.com",
        "matricula": "---"
    },
    {
        "idCiclo": 6,
        "firstName": "Fidel Martin",
        "lastName": "Saavedra Andres",
        "dni": "70981870",
        "gender": "Female",
        "age": 23,
        "email": "fidelmartinsaavedraandres@gmail.com",
        "matricula": "---"
    },
    {
        "idCiclo": 6,
        "firstName": "Franco Samuel",
        "lastName": "Salazar Salas",
        "dni": "75827603",
        "gender": "Female",
        "age": 19,
        "email": "francosamuelsalazarsalas@gmail.com",
        "matricula": "---"
    },
    {
        "idCiclo": 6,
        "firstName": "Claudio Juniors",
        "lastName": "Saldarriaga Torrejon",
        "dni": "72178780",
        "gender": "Female",
        "age": 24,
        "email": "claudiojuniorssaldarriagatorrejon@gmail.com",
        "matricula": "---"
    },
    {
        "idCiclo": 6,
        "firstName": "Fernando Elidor",
        "lastName": "Samame Rodriguez",
        "dni": "76111524",
        "gender": "Female",
        "age": 19,
        "email": "fernandoelidorsamamerodriguez@gmail.com",
        "matricula": "---"
    },
    {
        "idCiclo": 6,
        "firstName": "Armando Martin",
        "lastName": "Soria Lopez",
        "dni": "46729931",
        "gender": "Female",
        "age": 20,
        "email": "armandomartinsorialopez@gmail.com",
        "matricula": "---"
    },
    {
        "idCiclo": 6,
        "firstName": "Amy Nayely",
        "lastName": "Soriano Tuanama",
        "dni": "76111525",
        "gender": "Female",
        "age": 18,
        "email": "amynayelysorianotuanama@gmail.com",
        "matricula": "---"
    },
    {
        "idCiclo": 6,
        "firstName": "Joisy Fernanda",
        "lastName": "Torres Marquez",
        "dni": "72602146",
        "gender": "Female",
        "age": 23,
        "email": "joisyfernandatorresmarquez@gmail.com",
        "matricula": "---"
    },
    {
        "idCiclo": 6,
        "firstName": "Abraham",
        "lastName": "Vasquez Arevalo",
        "dni": "75579634",
        "gender":25,
        "email":"abrahamvasquezarevalo@gmail.com",
        "matricula": "---"
    },
    {
        "idCiclo": 6,
        "firstName": "Farid Caled",
        "lastName": "Zamora Echevarria",
        "dni": "75574634",
        "gender":25,
        "email":"faridcaledzamoraechevarria@gmail.com",
        "matricula": "---"
    }
    
]


const Student = () => {

    const handleSubmit = () => {
        dataStudent.forEach(course => {
        axios.post('http://localhost:3001/Student', course)
          .then(response => {
            console.log(`student  submitted successfully`, response);
          })
          .catch(error => {
            console.error(`There was an error submitting the course !`, error);
          });
      });
    };
  
    return (
      <div>
        <button onClick={handleSubmit}>Submit All Students</button>
      </div>
    );
  };
  
  export default Student;